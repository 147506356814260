<template>
	<div class="search">
		<search-bar @search="search" :_searchVal="searchVal" />
		<div class="search-content">
			<div class="search-left" v-loading="loading" element-loading-background="rgba(18, 19, 48, 1)">
				<div class="search-left-title" v-show="result.length">
					<span class="search-left-title-1">搜索结果</span>
					<span class="search-left-title-2">{{ result.length }}条结果</span>
				</div>
				<div class="search-left-box" v-show="result.length">
					<div class="search-left-box-item" v-for="(item, index) in result" :key="index">
						<div class="search-left-box-item-img" @click="goDetail(item.videoId)">
							<!-- <img :src="getImg(item.poster, 120, 170)" alt="" /> -->
							<lazy-pic :array="[item.poster, 120, 170]" :type="'poster'" :isWrapper="'a'" />
						</div>
						<div class="search-left-box-item-title">
							<div class="search-left-box-item-title-1">
								<div class="search-left-box-item-title-1-left" @click="goDetail(item.videoId)">
									<!-- {{ item.title }} -->
									<span
										v-for="(t, tIndex) in item.title"
										:key="tIndex"
										:style="{
											color: searchVal.includes(t) ? '#FF3465' : '#b6b7c3',
										}"
										>{{ t }}</span
									>
								</div>
								<div class="search-left-box-item-title-1-right">
									<span class="search-left-box-item-title-1-right-rating">{{
										item.rating.toFixed(1)
									}}</span>
									<span class="search-left-box-item-title-1-right-ratingfull"
										>/{{ item.ratingFull }}</span
									>
								</div>
							</div>
							<div class="search-left-box-item-title-tags">
								<div
									class="search-left-box-item-title-tags-item"
									v-for="(tag, index2) in item.tags.slice(0, 5)"
									:key="index2"
								>
									{{ tag.tagName }}
								</div>
							</div>
							<div class="search-left-box-item-title-desc">
								{{ item.intro }}
							</div>
						</div>
					</div>
					<div class="bodymovinanim" v-show="loadingShow">
						<div>
							<img :src="loadingIcon" alt="" />
						</div>
					</div>
					<!-- <page-select
            v-show="result.length"
            :pagination="pagination"
            @pageChange="pageChange"
          ></page-select> -->
				</div>
				<!--  -->
				<div class="empty-box" v-show="!result.length">
					<div class="empty-box-title" v-show="searchVal && !result.length">
						暂未找到
						<span class="keyword">"{{ searchVal }}"</span>
						相关的视频
					</div>
					<div class="empty-box-title2" v-show="emptyList.length">
						以下结果来自
						<span class="keyword">探索</span>
					</div>
					<div
						class="empty-box-list"
						v-loading="emptyLoading"
						element-loading-background="rgba(18, 19, 48, 1)"
					>
						<div v-for="(item, index) in emptyList" :key="index" class="result-item">
							<div class="result-item-img">
								<img :src="getImg(item.videoInfo.poster, '166', '240')" alt="" />

								<div class="moive-tag">
									{{ typeObject[item.videoInfo.videoType] }}
								</div>
							</div>
							<div class="result-item-text">
								<div class="result-item-text-box">
									<div class="result-item-text-box-title">
										{{ item.videoInfo.name }}
									</div>
									<div class="date">
										{{ item.videoInfo.releaseTime.slice(0, 4) }}
									</div>
								</div>
								<div
									class="moive-button"
									style="margin-left: 20px"
									v-show="item.status == 'new'"
									@click="addWatch(item)"
								>
									<img :src="wantIcon" alt="" />
									<span>我想看</span>
								</div>
								<div v-show="item.status === 'matching'">
									<div
										class="title-3"
										@click="addWatch(item)"
										style="cursor:pointer;background: rgba(182, 183, 195, 0.1);box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);border-radius: 5px;margin-left:20px; padding: 14px 12px;    display: flex;justify-content: center;align-items: center;"
									>
										<img
											:src="joinIcon"
											alt=""
											style="width: 20px;height: 20px;margin-right: 5px;"
										/>
										<span
											style="font-size: 13px;font-family: PingFang SC;font-weight: 500;line-height: 22px;color: #ff3465;"
											>还差{{ item.remain }}人</span
										>
									</div>
								</div>
								<div
									v-show="item.status == 'ongoing'"
									style="
                    margin-left: 20px;
                    margin-top: 40px;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    line-height: 28px;
                    color: #ff3465;
                  "
								>
									即将上映
								</div>
								<div
									v-show="item.status == 'deleted'"
									style="
                    margin-left: 20px;
                    margin-top: 40px;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    line-height: 28px;
                    color: #ff3465;
                  "
								>
									不符合条件
								</div>
								<div
									v-show="item.status == 'onshow'"
									style="
                    margin-left: 20px;
                    margin-top: 40px;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    line-height: 28px;
                    color: #797a8e;
                  "
								>
									已上映
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="search-right">
				<el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
					<el-tab-pane label="热搜榜" name="hot_search"> </el-tab-pane>
					<el-tab-pane label="热播榜" name="hot_play"> </el-tab-pane>
					<el-tab-pane label="热评榜" name="hot_commend"> </el-tab-pane>
					<el-tab-pane label="评分榜" name="rate"> </el-tab-pane>
					<el-tab-pane label="点赞榜" name="like"></el-tab-pane>
				</el-tabs>
				<div
					class="hot-wrapper"
					:style="{ height: searchLoading ? '400px' : 'auto' }"
					v-loading="searchLoading"
					element-loading-background="rgba(18, 19, 48, 1)"
				>
					<div v-show="!searchLoading">
						<div class="video-left" v-for="(item, index) in moiveList" :key="index">
							<div style="position: relative">
								<div
									class="detail-thumb"
									style="position: absolute; z-index: 1000; margin-top: 15px"
									@click="goDetail(item.videoId)"
								>
									<!-- <img :src="getImg(item.poster, 70, 100)" alt="" /> -->
									<lazy-pic :array="[item.poster, 70, 100]" :type="'poster'" :isWrapper="'a'" />
								</div>
								<div class="hot-detail">
									<div class="detail-title">
										<div class="text" @click="goDetail(item.videoId)">{{ item.title }}</div>
										<div class="rec-item-title2">
											<span>{{ typeObject[item.type] }}</span>
											<span>·</span>
											<span>{{ item.year }}</span>
											<span>·</span>
											<span>{{ item.country }}</span>
										</div>
										<div class="rating">
											<span class="rating-1">{{ item.rating.toFixed(1) }}</span>
										</div>
									</div>
									<div class="hot-detail-rank" v-show="index < 3">NO.{{ index + 1 }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
let isLoad = false
import searchBar from "./search-bar"
import pageSelect from "./page-select"
export default {
	name: "search",
	data() {
		return {
			// type: "resultEmpty",
			type: "result",
			// type: "normal",

			backgroundImage: require("@assets/index.png"),
			avatarImage: require("@assets/avatar.png"),
			wantIcon: require("@assets/explore/want.png"),
			timeIcon: require("@assets/aside/aside7.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			selectIcon: require("@assets/recommond/select.png"),
			selectIcon2: require("@assets/recommond/select2.png"),
			joinIcon: require("@assets/explore/join.png"),
			searchWidth: 0,
			searchPlaceHolder: "开始搜索",
			searchVal: "",
			user: {
				name: "胖虎不是铁憨憨",
			},
			loadingIcon: require("@assets/loading.gif"),

			activeName: "hot_search",
			moiveList: [],
			result: [],
			emptyList: [],

			pagination: {
				currentPage: 1,
				pageSize: 0,
				numFound: 0,
				totalPage: 0,
			},
			searchLoading: false,
			loading: false,

			typeObject: {
				movie: "电影",
				tv: "剧集",
			},
			emptyLoading: false,
			loadingShow: false,
			searchRecordId: null,
		}
	},

	methods: {
		async init() {
			this.searchLoading = true
			const result = await this.api.getSearchRank({
				type: this.activeName,
			})
			if (result.code == 1) {
				this.moiveList = result.data.list
			}
			this.searchLoading = false
			console.log(result)
		},
		pageChange(e) {
			this.loadingShow = true
			this.pagination.currentPage = e
			this.search(this.searchVal, true)
			console.log(e)
		},
		handleClick(tab, event) {
			this.init()
		},
		async search(keyword, isNext) {
			console.log(keyword)
			this.searchVal = keyword
			this.loading = !isNext
			if (!isNext) {
				isLoad = true
			}
			const result = await this.api.search({
				keyword,
				currentPage: this.pagination.currentPage,
			})
			if (result.code == 1) {
				this.pagination = result.data.pagination
				this.searchRecordId = result.data.searchRecordId

				if (isNext) {
					this.loadingShow = false
					this.result = [...this.result, ...result.data.list]
				} else this.result = result.data.list

				if (result.data.list.length === 0) {
					this.getExplore(keyword)
				}
			}
			this.loading = false
			setTimeout(() => {
				isLoad = false
			}, 500)
			console.log(result)
		},
		async getExplore(keyword) {
			this.emptyLoading = true
			const result = await this.api.getExploreSearch({
				keyword: this.searchVal,
			})
      console.log('result', result)
      if(result.code !== 32) {
        this.emptyList = result.data.list
      }
      this.emptyLoading = false
		},
		async addWatch(item) {
			console.log(item)
			const result = await this.api.addExplore({
				externalId: item.externalId,
				status: item.status,
				...item.videoInfo,

				exploreId: 1,
			})
			if (result.code === 1) {
				this.$message.success("正在组队中，还差" + result.data.number + "人")
				item.remain = result.data.number
				this.$forceUpdate()
			} else {
				this.$message.error(result.msg)
			}
		},
		getScroll() {
			let _this = this
			document.querySelector(".el-main").addEventListener(
				"scroll",
				function(e) {
					// console.log(e)
					if (_this.pagination.totalPage !== _this.pagination.currentPage) {
						_this.loadingShow = true
					}
					const { scrollHeight, scrollTop, offsetHeight } = e.target
					// console.log(parseInt(scrollTop + offsetHeight), scrollHeight);
					if (parseInt(scrollTop + offsetHeight) === scrollHeight) {
						console.log("到底了")

						setTimeout(() => {
							if (!isLoad) {
								console.log(_this.pagination)
								if (_this.pagination.totalPage === _this.pagination.currentPage) return
								_this.pagination.currentPage += 1
								_this.debounce(_this.pageChange(_this.pagination.currentPage), 1000)
                _this.loadingShow=false
							}
						}, 500)
					}
				},
				true
			)
		},
		debounce(fn, delay) {
			let timer = null
			return function() {
				if (timer) {
					clearInterval(timer)
					timer = setTimeout(fn, delay)
				} else {
					timer = setTimeout(fn, delay)
				}
			}
		},
		goDetail(videoId) {
			console.log('搜索记录', this.searchRecordId, videoId)
			this.api.searchRecord(this.searchRecordId, videoId).then(res=> {})
			if (location.href.includes('/detail')) {
			  this.$router.push('/detail?id=' + videoId)
				// location.href = '/detail?id=' + videoId
			} else {
        this.$router.push('/detail?id=' + videoId)
				// location.href = '/detail?id=' + videoId
				// this.$router.push('/detail?id=' + videoId);
			}
		},
	},
	mounted() {
		let val = this.$route.params.val
		this.searchWidth = (document.body.offsetWidth - 320) * 0.68
		this.init()
		this.getScroll()
		console.log(val)
		if (val) {
			this.searchVal = val
			this.search(val)
		}
	},
	components: {
		searchBar,
		pageSelect,
	},
}
</script>

<style lang="scss">
.search {
	background: rgba(18, 19, 48, 1);
	width: calc(100% - 100px);
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding: 0 50px;
	.search-content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		margin-top: 60px;
		.search-left {
			width: calc(100% - 300px);
			.search-left-title {
				.search-left-title-1 {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 600;
					line-height: 25px;
					color: #797a8e;
				}
				.search-left-title-2 {
					margin-left: 10px;

					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: #797a8e;
				}
			}
			.search-left-box {
				padding-top: 20px;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				flex-direction: column;
				.search-left-box-item {
					width: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					margin-bottom: 20px;
					.search-left-box-item-img {
						width: 120px;
						height: 170px;
						margin-right: 20px;
						cursor: pointer;
						border-radius: 5px;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
						}
					}
					.search-left-box-item-title {
						display: flex;
						justify-content: center;
						align-items: flex-start;
						flex-wrap: wrap;
						flex-direction: column;
						width: calc(100% - 190px);
						.search-left-box-item-title-1 {
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: flex-start;
							cursor: pointer;
							.search-left-box-item-title-1-left {
								font-size: 20px;
								font-family: PingFang SC;
								font-weight: 600;
								line-height: 33px;
								color: #b6b7c3;
							}
							.search-left-box-item-title-1-left:hover {
								cursor: pointer;
								text-decoration: underline;
							}
							.search-left-box-item-title-1-right {
								font-size: 18px;
								font-family: DINPro;
								font-weight: 500;
								line-height: 23px;
								color: #797a8e;
								.search-left-box-item-title-1-right-rating {
									font-size: 25px;
									font-family: DINPro;
									font-weight: bold;
									line-height: 32px;
									color: #ff3465;
									margin-right: 5px;
								}
							}
						}
						.search-left-box-item-title-tags {
							margin-top: 8px;
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;
							width: 100%;
							flex-wrap: wrap;
							.search-left-box-item-title-tags-item {
								background: rgba(255, 52, 101, 0.05);
								border-radius: 5px;
								padding: 3px 14px;
								margin-right: 10px;
								font-size: 12px;
								font-family: PingFang SC;
								font-weight: 500;
								line-height: 18px;
								color: #ff3465;
								cursor: pointer;
							}
						}
						.search-left-box-item-title-desc {
							margin-top: 18px;
							font-size: 13px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 22px;
							color: #797a8e;
							display: inline-block;
							overflow: hidden;
							text-overflow: ellipsis;
							cursor: pointer;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
						}
					}
				}
			}
		}
		.search-right {
			width: 300px;
		}
	}
}
.detail-thumb {
	border-radius: 5px;
	width: 70px;
	height: 100px;
	overflow: hidden;
	flex-shrink: 0;
	img {
		// width: 100%;
		// height: 100%;
	}
}
.hot-detail {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
	background: #1a1936;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	min-height: 134px;
	position: relative;
	overflow: hidden;
	margin-left: 50px;

	.detail-title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 35px;
		.text {
			font-size: 17px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 30px;
			color: #ffffff;
		}
		.text:hover {
			cursor: pointer;
			text-decoration: underline;
		}
		.rating {
			.rating-1 {
				font-size: 25px;
				font-family: DINPro;
				font-weight: bold;
				line-height: 32px;
				color: #d26176;
			}
			.rating-2 {
				font-size: 18px;
				font-family: DINPro;
				font-weight: 500;
				color: #797a8e;
			}
		}
		.rec-item-title2 {
			margin-top: 10px;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 20px;
			color: #ffffff;
			span {
				margin-right: 4px;
			}
		}
		.rec-item-title2 {
			.title-text2-name:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
	.hot-detail-rank {
		font-size: 37px;
		font-family: DINPro;
		font-weight: bold;
		line-height: 48px;
		color: rgb(95, 95, 113);
		position: absolute;
		right: 0;
		bottom: -13px;
	}
}
.empty-box {
	.empty-box-title {
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 28px;
		text-align: center;
		color: #999999;
		.keyword {
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 600;
			line-height: 28px;
			color: #ff3465;
		}
	}
	.empty-box-title2 {
		margin-top: 80px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 30px;
		color: #797a8e;
		.keyword {
			font-size: 22px;
			font-family: PingFang SC;
			font-weight: 600;
			line-height: 30px;
			color: #ff3465;
		}
	}
	.empty-box-list {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 28px;
		.result-item {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			margin-right: 100px;
			margin-bottom: 40px;
			width: 420px;
			.result-item-img {
				position: relative;
				width: 166px;
				height: 240px;
				height: auto;
				border-radius: 10px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					display: block;
				}
				.moive-tag {
					position: absolute;
					right: 8px;
					top: 8px;
					z-index: 11;

					height: 24px;
					background: #121330;
					opacity: 0.9;
					border-radius: 5px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;
					line-height: 20px;
					color: #ff3465;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 30px;
					width: 50px;
				}
			}
			.result-item-text {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: flex-start;
				width: 55%;
				.result-item-text-box {
					margin-left: 20px;
					width: 100%;
					margin-top: 0;
					.result-item-text-box-title {
						font-size: 19px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 26px;
						color: #b6b7c3;
						margin-top: 40px;
					}
					.result-item-text-box-title:hover {
						cursor: pointer;
						text-decoration: underline;
					}
					.date {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #797a8e;
						margin-top: 6px;
					}
					.date:hover {
						cursor: pointer;
						text-decoration: underline;
					}
				}
				.moive-button {
					width: 166px;
					height: 50px;
					background: rgb(26, 29, 54);
					border-radius: 5px;
					margin-top: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					margin-top: 60px;
					img {
						width: 30px;
						height: 30px;
						margin-right: 10px;
					}
					span {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 500;
						line-height: 22px;
						color: #797a8e;
					}
				}
			}
		}
	}
}

.search-right {
	.el-tabs__item {
		padding: 0;
		width: 54px;
	}
}

.search-right {
	.el-tabs__active-bar {
		background-color: #ff3465 !important;
		background-color: #ff3465 !important;
		width: 12px !important;
		left: 24px !important;
		bottom: 7px !important;
	}
}
</style>
